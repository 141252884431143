<template>
  <div class="inter-wrapper">
    <HeaderInter back-path="/user" />
    <div class="inter-content">
      <div v-if="$store.getters.memberInfo" class="orderinit-area container-fluid">
        <div class="order-area">
          <div class="box-group">
            <div class="box-items">
              <div class="box-th">
                姓名
              </div>
              <div class="box-td">
                {{ $store.getters.memberInfo.name }}
              </div>
            </div>
            <div class="box-items">
              <div class="box-th">
                聯絡電話
              </div>
              <div class="box-td">
                {{ $store.getters.memberInfo.phone }}
              </div>
            </div>
            <div class="box-items">
              <div class="box-th">
                電子信箱
              </div>
              <div class="box-td">
                {{ $store.getters.memberInfo.email }}
              </div>
            </div>
          </div>

          <div class="btn-area px-3">
            <router-link
              class="btn btn-next"
              to="/user/edit"
            >
              編輯資料
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { getMember } from '@/api/user'

export default {
  name: 'UserInfo',
  components: {
    HeaderInter
  },
  data() {
    return {}
  },
  created() {
    // 取會員資料
    this.getMemberInfo()
  },
  mounted() {},
  methods: {
    getMemberInfo() {
      // 取會員資料
      getMember().then((response) => {
        if (response.data.data.member_id !== undefined) {
          this.$store.commit('SET_MEMBER_INFO', response.data.data)
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "assets/scss/common.scss";
.box-group {
    margin: 0 auto 1.5rem;
  padding: 15px 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 8px 30px 0px rgba($main-color, $alpha: 0.15);
  .box-items {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    margin-bottom: 1rem;
    padding: .5rem 0 0;
    border-bottom: 1px solid rgba($main-color, $alpha: .15);
    .box-th {
      flex: 0 0 30%;
      padding: 5px 15px 5px 0px;
      white-space: nowrap;
      text-align: right;
    }
    .box-td {
      text-align: left;
      padding-left: 10px;
    }
  }
}
.box-items:last-child{
  border-bottom: 0px solid rgba($main-color, $alpha: .15);
}

</style>
